.tYBUYG_hamburger {
  z-index: var(--z-highest);
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-right: 3px;
  position: relative;
}

@media (width >= 600px) {
  .tYBUYG_hamburger {
    display: none;
  }
}

.tYBUYG_hamburger .tYBUYG_bar {
  background-color: var(--text-color);
  border-radius: 4px;
  width: 30px;
  height: 3px;
  padding: 0;
  transition: all .4s ease-in-out;
  display: block;
  position: absolute;
}

.tYBUYG_hamburger .tYBUYG_bar1, .tYBUYG_hamburger .tYBUYG_bar4 {
  transform-origin: 5%;
}

.tYBUYG_active.tYBUYG_hamburger > .tYBUYG_bar1 {
  width: 42px;
  height: 3px;
  transform: rotate(45deg);
}

.tYBUYG_active.tYBUYG_hamburger > .tYBUYG_bar3 {
  background-color: #0000;
  height: 3px;
  transform: rotate(45deg);
}

.tYBUYG_active.tYBUYG_hamburger > .tYBUYG_bar2 {
  background-color: #0000;
  height: 3px;
  transform: rotate(-45deg);
}

.tYBUYG_active.tYBUYG_hamburger > .tYBUYG_bar4 {
  width: 42px;
  height: 3px;
  transform: rotate(-45deg);
}

.tYBUYG_bar1 {
  top: 0;
}

.tYBUYG_bar2, .tYBUYG_bar3 {
  top: 13.5px;
}

.tYBUYG_bar3 {
  right: 0;
}

.tYBUYG_bar4 {
  bottom: 0;
}

.MqZHCq_header {
  padding: 10px var(--page-gutter) var(--grid-gap) var(--page-gutter);
  max-width: var(--page-max-width);
  place-content: center space-between;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.MqZHCq_headerImg {
  width: 200px;
  height: 100%;
  margin-left: -10px;
}

@media (width >= 600px) {
  .MqZHCq_headerImg {
    width: 240px;
  }
}

.MqZHCq_headerList {
  text-align: center;
  padding-left: 0;
  list-style-type: none;
}

.MqZHCq_headerList a {
  color: inherit;
  text-decoration: none;
}

@media (width >= 600px) {
  .MqZHCq_headerList {
    display: flex;
  }

  .MqZHCq_headerList li:not(:last-child) {
    margin-right: 40px;
  }
}

.MqZHCq_hamburg {
  cursor: pointer;
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  z-index: 1;
  justify-content: center;
  display: flex;
  position: relative;
}

@media (width >= 600px) {
  .MqZHCq_hamburg {
    display: none;
  }
}

.MqZHCq_hamburg:active .MqZHCq_line:nth-child(2) {
  opacity: 0;
}

.MqZHCq_hamburg:active .MqZHCq_line:first-child {
  transform: translateY(8px)rotate(45deg);
}

.MqZHCq_hamburg:active .MqZHCq_line:nth-child(3) {
  transform: translateY(-8px)rotate(-45deg);
}

.MqZHCq_headerNav {
  background: var(--page-background-color);
  z-index: var(--z-middle);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

@media (width >= 600px) {
  .MqZHCq_headerNav {
    background: unset;
    display: block;
    position: relative;
  }

  .MqZHCq_navItem {
    margin: 16px 0;
  }

  .MqZHCq_headerNav.MqZHCq_active {
    left: 0;
  }
}

.BOBUQq_footer {
  padding-bottom: var(--grid-gap);
  padding-top: calc(var(--grid-gap) * 2);
  width: 100%;
  display: flex;
}

.BOBUQq_footerList {
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.BOBUQq_className2 {
  padding-left: var(--page-gutter);
}

@media (width >= 700px) {
  .BOBUQq_footerList {
    flex-direction: row;
    justify-content: space-between;
  }
}

._7ZOsUa_container {
  max-width: var(--page-max-width);
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  width: 100%;
  margin: 0 auto;
}

.QR9Gsa_spinner {
  margin-top: var(--page-gutter);
}
/*# sourceMappingURL=index.2a789d92.css.map */
